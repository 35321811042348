:root {
  --hue: 174;
  --sat: 63%;
  --first-color: hsl(var(--hue), var(--sat), 40%);
  --first-color-alt: hsl(var(--hue), var(--sat), 36%);
}

.slider-cover .caption--gradient {
  background: transparent !important;
}

.uppc {

  text-transform: uppercase;

}

.header__icon--user img {
  max-width: 40px !important;
  border-radius: 100%;
  background: #fff;
}

.header__icon img {
  display: block;
  max-width: 24px;
}

.recipe-card {
  width: 100%;
  margin-bottom: 20px;
  border-radius: 15px;
  border: solid 1px #1d212c;
  position: relative;
  overflow: hidden;
}

.recipe-card aside {
  position: relative;
}

.recipe-card aside img {
  width: 100%;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}

.recipe-card aside .button {
  background: #57abf2;
  display: inline-block;
  position: absolute;
  top: 80%;
  right: 3%;
  width: 4.0625em;
  height: 4.0625em;
  border-radius: 4.0625em;
  line-height: 4.0625em;
  text-align: center;
}

.recipe-card aside .button .icon {
  vertical-align: middle;
}

.recipe-card article {
  background: #434959;
  padding: 1.25em 1.5em;
}

.recipe-card article ul {
  list-style: none;
  margin: 0.5em 0 0;
  padding: 0;
}

.recipe-card article ul li {
  display: inline-block;
  margin-left: 1em;
  line-height: 1em;
}

.recipe-card article ul li:first-child {
  margin-left: 0;
}

.recipe-card article ul li .icon {
  vertical-align: bottom;
}

.recipe-card article ul li span:nth-of-type(2) {
  position: relative;
  bottom: 5px;
  margin-left: 0.2em;
  font-size: 13px;
  font-weight: 500;
  vertical-align: middle;
  color: #fff;
}

.recipe-card article h2,
.recipe-card article h3 {
  margin: 0;
  font-weight: 300;
}

.recipe-card article h2 {
  font-size: 1.75em;
  color: #fff;
  padding: 0 0 9px 0;
}

.recipe-card article h3 {
  font-size: 0.9375em;
  color: #838689;
}

.recipe-card article p {
  margin: 1.25em 0;
  font-size: 14px;
  font-weight: 400;
  color: #fff;
}

.recipe-card article p span {
  font-weight: 700;
  color: #fff;
}

.recipe-card article .ingredients {
  margin: 1em 0 0.5em;
}

.recipe-card .icon {
  display: inline;
  display: inline-block;
  background-image: url(https://s3-us-west-2.amazonaws.com/s.cdpn.io/203277/recipe-card-icons.svg);
  background-repeat: no-repeat;
}

.recipe-card .icon-calories,
.recipe-card .icon-calories\:regular {
  background-position: 0 0;
  width: 16px;
  height: 19px;
}

.recipe-card .icon-clock,
.recipe-card .icon-clock\:regular {
  background-position: 0 -19px;
  width: 20px;
  height: 20px;
}

.recipe-card .icon-level,
.recipe-card .icon-level\:regular {
  background-position: 0 -39px;
  width: 16px;
  height: 19px;
}

.recipe-card .icon-play,
.recipe-card .icon-play\:regular {
  background-position: 0 -58px;
  width: 21px;
  height: 26px;
}

.recipe-card .icon-users,
.recipe-card .icon-users\:regular {
  background-position: 0 -84px;
  width: 18px;
  height: 18px;
}

.icones {

  font-size: 25px;
  color: #68FE69;

}

.card-set {

  padding: 39px;

}

.white-color {

  color: #FFF !important;

}

.card-set {

  margin-bottom: 20px;
  background: linear-gradient(60deg, #68fe9a 0%, #43dca4 100%) !important;

}

.card-set h3 {

  font-size: 2rem;
  padding: 0 0 15px 0;

}

.card-set h5 {

  font-size: 1.9rem;
  padding: 0 0 15px 0;

}

.mb-4,
.my-4 {
  margin-bottom: 1.5rem !important;
}

.list-unstyled {
  padding-left: 0;
  list-style: none;
}

.list-unstyled li {
  text-align: center;
}

.list-unstyled li {
  padding: 0.5rem 0;
  color: #fff;
  font-weight: 400;
  font-size: 16px;
}

.text-primary {
  color: #000 !important;
}

.coach-list {

  margin-top: 10px;

}

.user-profile {

  margin-top: 29px;

}

.user-profile__thumb img {

  background: #fff;

}

.page__content.page__content--with-header.page__content--with-bottom-nav,
.page__content.page__content--with-bottom-nav.page__content--with-header {
  height: calc(100vh - 145px) !important;
}

.page__content--with-header {
  height: calc(100vh - 145px) !important;
}

.slider-cover--round-corners .slider-cover__slide {
  margin-right: 10px !important;
}

.page__content {

  margin-top: 10px;
  pointer-events: all;

}

.page__contents {
  pointer-events: all;
  width: calc(100% - 40px);
  height: 100%;
  padding: 0 20px;
  position: relative;
}

.bottom-navigation {
  padding-bottom: calc(1.7rem + env(safe-area-inset-bottom));
}

.bottom-navigation__icons li img {
  max-width: 26px !important;
}

.card__more div img {
  display: block;
  max-width: 100%;
}

.down-spacer {

  padding-bottom: 17px;

}

.slider-cover__more div img {
  display: block;
  width: 16px;
}

.slider-cover__more div {
  display: block;
  width: 30px;
  height: 30px;
  background-color: #fff;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.card__icon.info img {

  margin: 0 auto;

}

.cards--12 .card__title.info {

  text-align: center;
  font-weight: 400;

}

.cards--12 .card__title.info.stats {

  text-align: center;
  font-size: 1.6rem;
  font-weight: 500;

}

.black-color {

  color: black;

}

.card__more div img {
  display: block;
  max-width: 100%;
}

body {
  min-height: 100vh;
  /* mobile viewport bug fix */
  min-height: -webkit-fill-available;

}

html {
  height: -webkit-fill-available;
}

.back {

  margin-bottom: 14px
}

.btn-hide {

  background: transparent;
  border: 0px;

}